import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { formatDate } from '../components/common';
import EmptyState from '../components/empty-state';
import styles from '../styles/pages.module.css';

export const query = graphql`
	{
		cms {
			allMagazines {
				title
				posted
				tags {
					name
				}
				file {
					publicUrl
				}
				image {
					publicUrl
					gatsbyFile {
						childImageSharp {
							fluid(maxWidth: 700, maxHeight: 1000) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`;

export default ({ data }) => (
	<Layout id={styles.journal}>
		<SEO
			title='Journals'
			keywords={['journal', ...data.cms.allMagazines.flatMap(({ tags }) => tags.map(({ name }) => name))]}
			description='IEEE SSCS AlexSC Journals'
		/>
		{data.cms.allMagazines.length === 0 ? (
			<EmptyState />
		) : (
			data.cms.allMagazines.map((node, ind) => (
				<a key={ind} href={node.file ? node.file.publicUrl : undefined}>
					<li className='card'>
						<Img fluid={node.image.gatsbyFile.childImageSharp.fluid} />
						<section>
							<p>{formatDate(node.posted)}</p>
							<h2>{node.title}</h2>
						</section>
					</li>
				</a>
			))
		)}
	</Layout>
);
